<script setup lang="ts">
const { data, suspense } = useProductCatalogue()

await suspense()
</script>

<template>
  <section class="bg-white pb-5xl pt-xl overflow-x-hidden">
    <ContentGrid class="gap-y-xl items-center 2xl:[--after-bg:theme(colors.blurple.100)]">
      <div class="col-span-4 lg:col-span-5 2xl:col-span-6 2xl:col-start-2 flex flex-col gap-l pr-2xl">
        <h1 v-text="data?.title" />

        <CrystallizeRichText
          v-for="(block, key) in data?.description"
          :key="key"
          class="font-heading text-2xl font-light"
          :block="block"
        />
      </div>

      <div class="hidden lg:block relative" aria-hidden="true">
        <Shape variant="decagon" class="fill-blurple-200 h-[360px]" />
        <Shape variant="hexagon" class="fill-blurple-100 absolute inset-0 h-[363px] translate-x-full -translate-y-[2px]" />
      </div>
    </ContentGrid>
    <ContentGrid class="mt-xl">
      <NettbutikkProductCta v-if="data?.highlighted" class="full-width-content" :product="data?.highlighted" />
    </ContentGrid>
  </section>
</template>
