<script setup lang="ts">
const { data, suspense } = useProductCatalogue()

await suspense()
</script>

<template>
  <section v-if="data?.products?.length" class="bg-blurple-100 pt-2xl pb-5xl">
    <ContentGrid class="gap-y-12">
      <div class="pt-m flex flex-col gap-s col-span-4 lg:col-span-7 2xl:col-start-2">
        <h3 v-text="data?.productsTitle" />
        <CrystallizeRichText
          v-for="(block, key) in data?.productsDescription"
          :key="key"
          class="text-lg font-light"
          :block="block"
          :variables="{
            // TODO: Get from CMS
            upgradeThresholdCount: formatNumberAsText(3),
            highlightedTitle: data?.highlighted?.components?.tittel ?? 'Juridika Premium',
          }"
        />
      </div>
      <ul class="full-width-content flex flex-col gap-m">
        <li v-for="product in data.products" :key="product.id">
          <NettbutikkProductHorizontalCard :product="product" />
        </li>
      </ul>
    </ContentGrid>
  </section>
</template>
