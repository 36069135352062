<script setup lang="ts">
definePageMeta({
  layout: 'nettbutikk',
  colorMode: 'light',
  theme: 'blurple',
  breadcrumb: {
    label: 'Våre abonnement',
  },
})

useHead({
  bodyAttrs: {
    class: 'bg-white',
  },
})
</script>

<template>
  <section>
    <NettbutikkProductCatalogueHeader />
    <NettbutikkProductCatalogueList />
  </section>
</template>
